<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="width:500px">
      <CCol>      
        <CSelect
          id="selectPeriod"
          label="Periode"
          :options="periodOptions"
          :value.sync="selectedPeriod"
          horizontal
          @change="onChangePeriodCity()"
        />
      </CCol>
      <CCol>      
        <CSelect
          id="selectCity"
          label="Kota"
          :options="cityOptions"
          :value.sync="selectedCity"
          horizontal
          @change="onChangePeriodCity()"
        />
      </CCol>
    </CRow>

    <CRow style="margin-bottom:20px; width:600px">
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewTopic()">
          Topik baru
        </CButton>
      </CCol>
    </CRow>

    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>   
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialogEdit" :title="title" hide-footer>
    <div style="padding: 10px">
      <CSelect
        id="selectPeriod"
        label="Periode"
        :options="periodOptions"
        :value.sync="record.studyPeriodId"
        horizontal
      />
      <CSelect
        id="selectCity"
        label="Kota"
        :options="cityOptions"
        :value.sync="record.cityId"
        horizontal
      />
      <CInput
        label="Topik"
        v-model="record.topic"
        horizontal
      />
      <CRow style="width:280px; margin:10px" >
        <CCol><label for="datepicker">Tanggal kotbah</label></CCol>
        <CCol><Datepicker placeholder="Pilih tanggal" v-model="record.deliveryDate" format="yyyy-MM-dd"/></CCol>
      </CRow>
      <CInput
        label="Pengajar"
        v-model="record.preacher"
        horizontal
      />
      <CTextarea
        label="Catatan"
        v-model="record.note"
        horizontal
        rows="3"
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogEditClose()">Batal</CButton>
      <CButton @click="dialogEditSave()">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
  import api from "../../api/api"
  import Datepicker from 'vuejs-datepicker';

  const fields = [
    { 
      key: 'topic', 
      label: 'Topik', 
      _style: 'min-width:100px'
    },
    { 
      key: 'deliveryDateFormatted', 
      label: 'Tanggal', 
      _style: 'min-width:50px'
    },
    { 
      key: 'preacher', 
      label: 'Pengajar', 
      _style: 'min-width:70px'
    },
    { 
      key: 'show_edit', 
      label: '', 
      _style: 'width:1%'
    },
    { 
      key: 'show_delete', 
      label: '', 
      _style: 'width:1%'
    }
  ]

  export default {
    components: {
      Datepicker
    },

    data () {
      return {
        title: '',
        isEdit: false,

        showLoading: false,
        items: [],
        itemsAll: [],
        fields,
        details: [],
        showDialogEdit: false,
        record: '',

        periods: [],
        periodOptions: [],

        cities: [],
        cityOptions: [],

        selectedPeriod: '',
        selectedCity: '',
        user: ''
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listCity()
      }
    },

    methods: {
      async listCity () {
        this.showLoading = true
        let apiRes = await api.listCity()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.cities = apiRes.cities

          this.cityOptions = []
          for(var i=0; i<this.cities.length; i++){
            var option = {value: this.cities[i].id, label: this.cities[i].city}
            this.cityOptions.push(option)
          }

          if(this.cities.length>0){
            this.selectedCity = this.cities[0].id
            this.listPeriod()
          }
        } 
        else {
          console.log("listCity ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listPeriod () {
        this.showLoading = true
        let apiRes = await api.listPeriod()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.periods = apiRes.periods

          this.periodOptions = []
          for(var i=0; i<this.periods.length; i++){
            var option = {value: this.periods[i].id, label: this.periods[i].name}
            this.periodOptions.push(option)
          }

          if(this.periods.length>0){
            this.selectedPeriod = this.periods[0].id
            this.listBahanPsk()
          }
        } 
        else {
          console.log("listBahanPsk ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listBahanPsk () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listBahanPsk("ALL")
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.currentPeriod = apiRes.data.currentPeriodName
          this.itemsAll = apiRes.data.items

          this.onChangePeriodCity()
        } 
        else {
          console.log("listBahanPsk ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickEdit (item) {
        this.title = "Edit Topik Persekutuan"
        this.isEdit = true
        this.record = item
        this.showDialogEdit = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus Topik ini?").then(() => {
          this.executeDeleteBahanPsk()
        });
      },

      async executeDeleteBahanPsk(){
        this.showLoading = true
        let apiRes = await api.deleteBahanPsk(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Topik persekutuan sudah dihapus!")
          this.listBahanPsk()
        } 
        else {
          console.log("deleteBahanPsk ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }      
      },

      dialogEditClose(){
        this.showDialogEdit = false
      },

      async dialogEditSave(){
        if(this.record.topic.trim()==='' || this.record.note.trim()==='' || this.record.preacher.trim()==='' || this.record.deliveryDate==='')
          this.$alert("Semua data harus diisi!")
        else{
          this.showDialogEdit = false

          var dtDelivery = Date.parse(this.record.deliveryDate)
          if(isNaN(dtDelivery)) dtDelivery = this.record.deliveryDate
          this.record.deliveryDate = dtDelivery

          if(this.isEdit){
            this.showLoading = true
            let apiRes = await api.updateBahanPsk(this.user.id, this.record)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listBahanPsk()
            } 
            else {
              console.log("listBahanPsk ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
          else{
            this.showLoading = true
            let apiRes = await api.createBahanPsk(this.user.id, this.record)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listBahanPsk()
            } 
            else {
              console.log("createBahanPsk ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },

      onChangePeriodCity(){
        this.items = []
        for(var i=0; i<this.itemsAll.length; i++){
          if(this.itemsAll[i].studyPeriodId===this.selectedPeriod && this.itemsAll[i].cityId===this.selectedCity) this.items.push(this.itemsAll[i])
        }
      },

      onClickNewTopic(){
        this.title = "Buat Topik Baru"
        this.isEdit = false

        this.record = {
          studyPeriodId: this.periodOptions[0].value,
          cityId: '1',
          topic: '',
          note: '',
          preacher: '',
          deliveryDate: ''
        }
        this.showDialogEdit = true
      }
    },

  }
</script>
